var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{staticClass:"notification-detail",attrs:{"spinning":_vm.loading}},[_c('h2',[_vm._v(" "+_vm._s(_vm.$route.name)+" ")]),_c('a-divider'),_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_c('div',{directives:[{name:"decorator",rawName:"v-decorator",value:(['id']),expression:"['id']"}]})]),_c('a-form-item',{attrs:{"label":"标题","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'title',
          {
            rules: [{ max: 80, message: '请输入不超过 80 个字符！' }]
          }
        ]),expression:"[\n          'title',\n          {\n            rules: [{ max: 80, message: '请输入不超过 80 个字符！' }]\n          }\n        ]"}]})],1),_c('a-form-item',{attrs:{"label":"内容","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol,"extra":_vm.extra}},[_c('v-editor',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'content',
          {
            rules: [
              { required: true, message: '请填写消息内容！' },
              { max: 2000, message: '请输入不超过 2000 个字符！' }
            ]
          }
        ]),expression:"[\n          'content',\n          {\n            rules: [\n              { required: true, message: '请填写消息内容！' },\n              { max: 2000, message: '请输入不超过 2000 个字符！' }\n            ]\n          }\n        ]"}],staticClass:"editor",attrs:{"modules":{ toolbar: [['link', 'clean']] }},on:{"change":function (value) { return (_vm.content = value); }}})],1),_c('a-form-item',{attrs:{"label":"发布时间","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'publish_at',
          {
            rules: [
              {
                required: true,
                message: '请选择发布时间！'
              }
            ]
          }
        ]),expression:"[\n          'publish_at',\n          {\n            rules: [\n              {\n                required: true,\n                message: '请选择发布时间！'\n              }\n            ]\n          }\n        ]"}],attrs:{"show-time":"","valueFormat":"YYYY-MM-DDTHH:mm:ssZ"}})],1),_c('a-form-item',{attrs:{"label":"发布范围","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'type',
          {
            initialValue: 'public'
          }
        ]),expression:"[\n          'type',\n          {\n            initialValue: 'public'\n          }\n        ]"}]},[_c('a-radio',{attrs:{"value":"public"}},[_vm._v(" 全员公开 ")]),_c('a-radio',{attrs:{"value":"private"}},[_vm._v(" 指定用户 ")])],1)],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'private'),expression:"type === 'private'"}],attrs:{"label":"接收用户 ID","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('v-user-ids',{directives:[{name:"decorator",rawName:"v-decorator",value:(['user_ids', { initialValue: [] }]),expression:"['user_ids', { initialValue: [] }]"}]})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'private'),expression:"type === 'private'"}],attrs:{"label":"导入接收用户名单","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('v-upload-user-ids',{on:{"upload":function($event){return _vm.form.setFieldsValue({ user_ids: $event })}}})],1)],1),_c('a-divider'),_c('div',{staticClass:"actions"},[_c('a-button',{staticClass:"submit",attrs:{"type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v(" 完成 ")]),_c('a-button',{staticClass:"close",on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" 返回 ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
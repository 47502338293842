

















































































































import Vue from "vue";
import moment from "moment";
import { mapState } from "vuex";
import { dispatch, Notification } from "@/store";

export default Vue.extend({
  data() {
    return {
      moment,
      loading: false,
      form: this.$form.createForm(this),
      labelCol: { span: 5 },
      wrapperCol: { span: 14 },
      notification: {} as Notification,
      type: "public",
      content: ""
    };
  },
  computed: mapState({
    id() {
      return this.$route.params.id;
    },
    isAdd() {
      return !this.id;
    },
    extra() {
      return /(https:\/\/|http:\/\/)/.test(this.content)
        ? "消息中包含网址，请检查是否需要使用超链接"
        : "";
    }
  }),
  methods: {
    onValuesChange(props: any, notification: Notification) {
      if (notification) {
        const { type } = notification;
        this.type = type || this.type;
      }
    },
    onSubmit() {
      if (this.loading) {
        return;
      }
      this.form.validateFieldsAndScroll(
        { scroll: { offsetTop: 200 } },
        (errors, values) => {
          if (!errors) {
            const notification: Notification = {
              ...values,
              user_ids: values.user_ids.map((id: string) => Number(id))
            };
            this.loading = true;
            if (this.isAdd) {
              dispatch
                .notificationsPost(notification)
                .then(() => {
                  this.$message.success("消息已添加");
                  this.$router.push(this.$paths.notifications);
                  this.loading = false;
                })
                .catch(() => {
                  this.loading = false;
                });
            } else {
              dispatch
                .notificationsPatch(notification)
                .then(() => {
                  this.$message.success("消息已修改");
                  this.loading = false;
                })
                .catch(() => {
                  this.loading = false;
                });
            }
          }
        }
      );
    }
  },
  mounted() {
    this.form = this.$form.createForm(this, {
      onValuesChange: this.onValuesChange
    });
    if (this.id) {
      this.loading = true;
      dispatch
        .notificationsGet(Number(this.id))
        .then((notification: Notification) => {
          this.notification = notification;
          this.form.setFieldsValue({
            ...notification
          });
          this.loading = false;
        });
    }
  }
});
